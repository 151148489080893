import React from 'react'
import { Container, Box, Flex } from 'theme-ui'
import { FooterLogo } from './Footer.Logo'
import { FooterSocial } from './Footer.Social'
import { FooterMenu } from './Footer.Menu'

const styles = {
  wrapper: {
    position: `relative`,
    bg: `footerBg`,
    mt: [7, 6]
  },
  footerLogo: {
    display: `contents`,
  },
  footerSocial: {
    position: `relative`,
    top: `7em`,
    display: `block`,
    '@media screen and (min-width: 500px)': {
      display: `none`
    }
  },
  footerMenu: {
    '@media screen and (max-width: 500px)': {
        position: `relative`,
        top: `4.2em`
      },
  }
}

export const Footer = () => (
  <Box sx={styles.wrapper}>
    <Container variant='compact' sx={styles.container}>
      <Flex variant='layout.footer'>
        <Box sx={styles.footerMenu}>
          <FooterMenu />
        </Box>
        <Box sx={styles.footerLogo}>
          <FooterLogo />
        </Box>
        <Box sx={styles.footerSocial}>
          <FooterSocial />
        </Box>
      </Flex>
    </Container>
  </Box>
)
