import React from 'react'
import { Box } from 'theme-ui'
import IconButton from '../../flow-ui-components/IconButton/IconButton'

import "./Categories.css"

const Categories = ({ variant, categories }) => (
      <Box className="categories-list">
      {categories && categories.sort(function(a, b){
        if(a.name < b.name) { return 1; }
        if(a.name > b.name) { return -1; }
        return 0;
      }).map(({ id, name, slug, totalCount }) => {
          const buttonProps = {
            key: id,
            name,
            number: totalCount,
            to: slug,
            variant
          }

          return totalCount !== 0 && <li><IconButton {...buttonProps} /></li>
        })}
        </Box>
)

export default Categories

Categories.defaultProps = {
  variant: 'vertical'
}
