import React from 'react'
import { Box } from 'theme-ui'
import { HeaderLogo } from '../Header/Header.Logo'

const styles = {
  logo: {
    '@media screen and (max-width: 768px)': {
      display: `none`
    },
    position: `relative`,
    bottom: `20px`
  },
  copyright: {
  }
}

export const FooterLogo = () => (
  <>
    <Box sx={styles.logo}>
      <HeaderLogo grayscale />
    </Box>
    <Box sx={styles.copyright}>
      © {new Date().getFullYear()}, All Rights Reserved.
    </Box>
  </>
)
