export default {
    layout: {
      flexDirection: 'column',
      height: `100vh`
    },
    header: {
      justifyContent: `space-between`,
      alignItems: `center`,
      height: [`3rem`, `4rem`], //prevent layout shift
      py: [3, 4],
      my: [1, 2],
    },
    body: {
      flex: `1 1 auto`
    },
    footer: {
      flexDirection: [`column-reverse`, `row`],
      justifyContent: `space-between`,
      alignItems: [`center`, `flex-start`],
      height: `1vh`,
      py: 5
    },
  
    /** Container */
    container: {
      maxWidth: `container`,
      px: [3, 4],
      '@media (max-width:800px)': {
        px: 0,
        wordBreak: `break-word`,
        fontSize: `18px`
      },
    },
    /** Container (full width version) */
    wide: {
      maxWidth: `wide`,
      px: [3, 4]
    },
    full: {
      maxWidth: `none`
    },
    /** Container (compact version) */
    compact: {
      px: [3, 4]
    },
  
    /** Main Container with sidebar support */
    main: {
      flex: 1,
      ml: 0
    },
    /** Sidebar */
    sidebar: {
      display: [`none`, null, `block`],
      flexBasis: `1/3`,
      pl: 5,
      '@media screen and (min-width: 900px)': {
        mr: `-14em`
      },
      '@media (max-width:1080px)': {
        display: `none`
      },
    },
  
    /** Sections in container */
    section: {
      maxWidth: `none`
    },
  
    /** Sidebar Container */
    'section-aside': {
      variant: `layout.section`
    },
  
    /** Sticky Box */
    sticky: {
      position: `sticky`,
      top: 4
    }
  }
  