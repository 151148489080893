import {
    FaGithub,
    FaTwitter,
    FaDiscord,
    FaInstagram,
    FaLinkedinIn,
    FaFacebook,
    FaYoutube,
    FaEnvelope,
    FaSteam,
    FaLink,
    FaGlobe
  } from 'react-icons/fa'
  
  export default {
    default: {
      Icon: FaLink,
      color: '#718096'
    },
    github: {
      Icon: FaGithub,
      color: 'darkslategray',
      size: 30
    },
    twitter: {
      Icon: FaTwitter,
      color: '#1DA1F2',
      size: 30
    },
    discord: {
      Icon: FaDiscord,
      color: '#7289da',
      size: 30
    },
    steam: {
      Icon: FaSteam,
      color: '#c7d5e0',
      size: 30
    },
    youtube: {
      Icon: FaYoutube,
      color: '#c7d5e0',
      size: 30
    },
    instagram: {
      Icon: FaInstagram,
      color: '#C13584'
    },
    linkedin: {
      Icon: FaLinkedinIn,
      color: '#2867B2'
    },
    facebook: {
      Icon: FaFacebook,
      color: '#4267B2',
      size: 30
    },
    email: {
      Icon: FaEnvelope,
      color: '#718096'
    },
    website: {
      Icon: FaGlobe,
      color: 'darkslategray'
    }
  }
  