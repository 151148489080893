import React, { useEffect } from 'react'
import { Global } from '@emotion/core'
import { ThemeProvider, Flex, Box, css } from 'theme-ui'
import theme from '@elegantstack/flow-ui-theme/src/theme'
import pageContextProvider from '@helpers/pageContextProvider'
import { Header } from '../Header/Header'
import { Footer } from '../Footer/Footer'

import ScrollArrow from '../../components/BackToTop';


export const Layout = ({ children, pageContext, location }) => {
  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "//cdn.embedly.com/widgets/platform.js";
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
  <ThemeProvider theme={theme}>
    <pageContextProvider.Provider value={{ pageContext, location }}>
      <Flex variant='layout.layout'>
        <Global styles={css(theme => theme.global)} />
        <Header />
        <Box variant='layout.body'>{children}</Box>
        <Footer />
        <ScrollArrow />
      </Flex>
    </pageContextProvider.Provider>
  </ThemeProvider>
  )}
