import React, { useState } from 'react';
import { FaArrowCircleUp } from 'react-icons/fa';
import './BackToTop.css';

const ScrollArrow = () =>{
  const [showScroll, setShowScroll] = useState(false)
  const isBrowser = typeof window !== "undefined"
  
  const checkScrollTop = () => {
    if (isBrowser) {
      if (!showScroll && window.pageYOffset > 400){
        setShowScroll(true)
      } else if (showScroll && window.pageYOffset <= 400){
        setShowScroll(false)
      }
    }
  };

  const scrollTop = () =>{
    isBrowser && window.scrollTo({top: 0, behavior: 'smooth'});
  };

  isBrowser && window.addEventListener('scroll', checkScrollTop)

  return (
    <div className="scrollTop" style={{display: showScroll ? 'block' : 'none'}}>
      <FaArrowCircleUp className="iconTop" size={40} onClick={scrollTop}/>
    </div>
  );
}

export default ScrollArrow;