const heading = {
    display: `block`,
    color: `heading`,
    fontWeight: `heading`,
    textDecoration: `none`,
    mb: 3
  }
  
  export default {
    heading: {
      ...heading
    },
    h1: {
      ...heading,
      '@media screen and (min-width: 768px)': {
        fontSize: 46,
      },
      fontSize: 30,
      lineHeight: `56px`,
      fontWeight: `bolder`
    },
    h2: {
      ...heading,
      fontSize: 30,
      '@media (max-width:800px)': {
        fontSize: 25
      },
    },
    h3: {
      ...heading,
      fontSize: 3
    },
    h4: {
      ...heading,
      fontSize: 2
    },
    h5: {
      ...heading,
      fontSize: 1
    },
    medium: {
      fontSize: 3,
      mb: 3
    },
    small: {
      fontSize: 1,
      m: 0
    },
    xsmall: {
      fontSize: 0,
      m: 0
    },
    p: {
      fontFamily: `body`,
      mb: 3,
    }
  }
  