import icons from '../../../../packages/common/gatsby-common-helpers/src/default.social.icons'
import extractHostname from '../../../../packages/common/gatsby-common-helpers/src/extractHostname'

const attachSocialIcons = items =>
  items &&
  items.length &&
  items.map(item => {
    const name = (item.name || extractHostname(item.url || item)).toLowerCase()
    return {
      url: item.url || item,
      ...(icons[name] || icons.default)
    }
  })

export default attachSocialIcons
