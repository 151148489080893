import React, { useContext } from 'react'
import { Box, Flex } from 'theme-ui'
import MediaQuery from 'react-responsive'
import pageContextProvider from '@helpers/pageContextProvider'
import Search from '@widgets/Search'
import { useBlogCategories } from '@helpers-blog'
import Categories from '../../flow-ui-widgets/Categories/Categories'

import { HeaderLogo } from './Header.Logo'
import { HeaderMenu } from './Header.Menu'
import { HeaderColorMode } from '@layout/Header/Header.ColorMode'

const styles = {
  wrapper: {
    position: `relative`,
  },
  categoryWrapper: {
    bg: `headerBg`,
    height: `4em`,
    padding: `0.6em`,
    display: `flex`,
    justifyContent: `center`
  },
  categoryContainer: {
    display: `flex`,
    alignItems: `flex-start`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    width: `100%`,
    '@media screen and (max-width: 1000px)': {
      display: `none`,
    },
  },
  logoContainer: {
    flexBasis: [`full`, null, `1/3`],
    margin: `0 2em 0 2em`
  },
  searchContainer: {
    flexBasis: [`auto`, null, `1/3`],
    minWidth: `auto`,
    order: [3, null, `unset`],
    mx: 3
  },
  menuContainer: {
    flexBasis: [`auto`, null, `1/3`],
    '@media screen and (max-width: 1100px)': {
      flexBasis: `45.333%`,
    },
    minWidth: `auto`,
    order: [4, null, `unset`]
  },
  colorModeContainer: {
    minWidth: `auto`,
    order: [2, null, `unset`],
    margin: `0 2em 0 2em`

  }
}

export const Header = ({ children }) => {
  const context = useContext(pageContextProvider)

  const { services, mobileMenu, darkMode } = context.pageContext
  const algolia = services && services.algolia
  const categories = useBlogCategories()


  return (
    <Box sx={styles.wrapper}>
      <Flex variant='layout.header'>
        <Box sx={styles.logoContainer}>
          <HeaderLogo />
        </Box>
        <Box sx={styles.categoryContainer}>
          <Categories categories={categories}/>
        </Box>
        <Box sx={styles.searchContainer}>{algolia && <Search />}</Box>
        <Box sx={styles.menuContainer}>
          <HeaderMenu mobileMenu={mobileMenu} />
        </Box>
        <Box sx={styles.colorModeContainer}>
          {darkMode && <HeaderColorMode />}
        </Box>
      </Flex>
      {children}
    </Box>
  )
}
