import React from 'react'
import { Link } from 'gatsby'
import { Heading, Badge, Flex } from 'theme-ui'
import Divider from '@components/Divider'

const styles = {
  wrapper: {
    justifyContent: `space-between`,
    alignItems: `end`
  },
  title : {
    fontFamily : ['Barlow', 'sans-serif'],
    textTransform: `uppercase`,
    fontSize: `3em`,
    margin : `1em 0 0 0`,
    fontWeight: `1000`,
    fontStyle: `normal`,
    letterSpacing: `0px`,
    '@media screen and (max-width: 500px)': {
      width:`100%`,
      display:`flex`,
      justifyContent:`center`,
      fontSize: '2em'
    }
  },
  seeMore : {
    textDecoration: `underline`,
    textTransform: `uppercase`,
    letterSpacing: `1px`,
    fontFamily : ['Barlow', 'sans-serif'],
    '@media screen and (max-width: 900px)': {
      display: `none`
    }
  },
}

const SectionTitle = ({ title, titleLink, omitTitle, variant }) => {
  const linkProps = titleLink && {
    as: Link,
    to: titleLink
  }

  return !omitTitle && (title || titleLink) ? (
    <>
      <Flex sx={styles.wrapper}>
        {title && (
          <Heading variant='h2' sx={styles.title} {...linkProps}>
            {title}
          </Heading>
        )}
        {titleLink && (
          <Heading variant='h4' sx={styles.seeMore} {...linkProps}>
            Voir plus
          </Heading>
        )}
      </Flex>
      <Divider />
    </>
  ) : null
}

export default SectionTitle
